<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>单位认证</el-breadcrumb-item>
      </el-breadcrumb>
    </el-card>
    <div class="hr-20"></div>
    <el-button @click="goHome()" v-show="isMobile">回到首页</el-button>
    <div class="hr-20"></div>
    <el-card>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="130px"
        :label-position="labelPosition"
      >
        <el-row>
          <el-col :span="16" :class="isMobile ? 'Mobilecol' : ''">
            <el-row>
              <el-col :span="12">
                <el-form-item label="单位名称：" prop="name">
                  <el-input
                    v-model.trim="form.name"
                    placeholder="单位名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="税务关系所在地："
                  prop="subarea"
                  label-width="150px"
                >
                  <el-select
                    v-model.trim="form.subarea"
                    placeholder="请选择税务关系所在地"
                  >
                    <el-option label="经开区" value="经开区"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="单位性质：" prop="nature">
                  <el-select
                    v-model.trim="form.nature"
                    placeholder="请选择单位性质"
                  >
                    <el-option label="机关" value="机关"></el-option>
                    <el-option label="事业单位" value="事业单位"></el-option>
                    <el-option label="企业" value="企业"></el-option>
                    <el-option label="学校" value="学校"></el-option>
                    <el-option label="医院" value="医院"></el-option>
                    <el-option label="社会团体" value="社会团体"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册资金(元)：">
                  <el-input
                    type="Number"
                    v-model.trim="form.registeredCapital"
                    placeholder="注册资金"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="单位法人：" prop="corp">
                  <el-input
                    v-model.trim="form.corp"
                    placeholder="单位法人"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单位联系人：" prop="contacts">
                  <el-input
                    v-model.trim="form.contacts"
                    placeholder="单位联系人"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="联系人电话：" prop="cellphone">
                  <el-input
                    v-model.trim="form.cellphone"
                    placeholder="联系人电话"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单位银行账户：" prop="bankCard">
                  <el-input
                    v-model.trim="form.bankCard"
                    placeholder="单位银行账户"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="单位银行账户开户行：" prop="bankDeposit">
                  <el-input
                    v-model.trim="form.bankDeposit"
                    placeholder="单位银行账户开户行"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单位银行账户收款人：" prop="payee">
                  <el-input
                    v-model.trim="form.payee"
                    placeholder="单位银行账户收款人"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="固定电话：" prop="telephone">
                  <el-input
                    v-model.trim="form.telephone"
                    placeholder="固定电话"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="社会信用代码：" prop="socialCreditCode">
                  <el-input
                    v-model.trim="form.socialCreditCode"
                    placeholder="社会信用代码"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="单位网站：" prop="website">
                  <el-input
                    v-model.trim="form.website"
                    placeholder="单位网站"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="注册日期" prop="establishTime">
                  <el-date-picker
                    :picker-options="pickerOptions"
                    v-model="form.establishTime"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="行业：" prop="industry">
                  <el-select v-model="form.industry" placeholder="请选择行业">
                    <el-option
                      label="农、林、牧、渔业 "
                      value="农、林、牧、渔业 "
                    ></el-option>
                    <el-option label="采矿业" value="采矿业"></el-option>
                    <el-option label="制造业" value="制造业"></el-option>
                    <el-option
                      label="电力、热力、燃气及水生产和供应业 "
                      value="电力、热力、燃气及水生产和供应业 "
                    ></el-option>
                    <el-option label="建筑业 " value="建筑业"></el-option>
                    <el-option
                      label="交通运输、仓储和邮政业 "
                      value="交通运输、仓储和邮政业"
                    ></el-option>
                    <el-option
                      label="信息传输、软件和信息技术服务业 "
                      value="信息传输、软件和信息技术服务业"
                    ></el-option>
                    <el-option
                      label="批发和零售业 "
                      value="批发和零售业"
                    ></el-option>
                    <el-option
                      label="住宿和餐饮业 "
                      value="住宿和餐饮业"
                    ></el-option>
                    <el-option label="金融业 " value="金融业"></el-option>
                    <el-option label="房地产业" value="房地产业"></el-option>
                    <el-option
                      label="租赁和商务服务业 "
                      value="租赁和商务服务业"
                    ></el-option>
                    <el-option
                      label="科学研究和技术服务业"
                      value="科学研究和技术服务业"
                    ></el-option>
                    <el-option
                      label="水利、环境和公共设施管理业"
                      value="水利、环境和公共设施管理业"
                    ></el-option>
                    <el-option
                      label="居民服务、修理和其他服务业"
                      value="居民服务、修理和其他服务业"
                    ></el-option>
                    <el-option label="教育" value="教育"></el-option>
                    <el-option
                      label="卫生和社会工作 "
                      value="卫生和社会工作"
                    ></el-option>
                    <el-option
                      label="文化、体育和娱乐业"
                      value="文化、体育和娱乐业"
                    ></el-option>
                    <el-option
                      label="公共管理、社会保障和社会组织"
                      value="公共管理、社会保障和社会组织"
                    ></el-option>
                    <el-option label="国际组织" value="国际组织"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="高新技术企业：" prop="highTech">
                  <el-radio-group v-model.trim="form.highTech">
                    <el-radio-button label="true">是</el-radio-button>
                    <el-radio-button label="false">否</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否留创项目：">
                  <el-radio-group v-model.trim="form.returneesEntrepreneurial">
                    <el-radio-button label="true">是</el-radio-button>
                    <el-radio-button label="false">否</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="是否规上企业：" prop="adsEnterprise">
                  <el-radio-group v-model.trim="form.adsEnterprise">
                    <el-radio-button label="true">是</el-radio-button>
                    <el-radio-button label="false">否</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="是否建有院士工作站">
                  <el-input v-model.trim="form.academicianWorkstation" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否建有博士后科研工作站">
                  <el-input v-model.trim="form.postDoctoralResearchCenter" />
                </el-form-item>
              </el-col>
            </el-row>

            <v-row>
              <v-col>
                <el-button
                  type="primary"
                  size="small"
                  style="color: #FFFFFF;margin: 0 0 16px 16px;float: left"
                  @click="getAddInnovationPlatforms"
                >
                  <i class="el-icon-plus"></i>
                  添加创新创业平台
                </el-button>
              </v-col>
            </v-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="创新创业平台：">
                  <el-input
                    rows="5"
                    v-model.trim="innovationPlatforms.platform"
                    placeholder="创新创业平台"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="认定部门：">
                  <el-input
                    rows="5"
                    v-model.trim="innovationPlatforms.recognitionDepartment"
                    placeholder="认定部门"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="获评时间：">
                  <el-date-picker
                    clearable
                    :picker-options="pickerOptions"
                    v-model="innovationPlatforms.evaluationTime"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="级别：">
                  <el-select
                    clearable
                    v-model.trim="innovationPlatforms.level"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in level"
                      :key="item.value"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <div
              v-for="(item, index) in form.innovationPlatforms"
              :key="item.number"
            >
              <el-row>
                <el-col :span="4">
                  <el-button
                    size="small"
                    type="danger"
                    class="mb-4"
                    @click="deleteInnovationPlatforms(item, index)"
                    >删除
                  </el-button>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="创新创业平台：">
                    <el-input
                      rows="5"
                      v-model.trim="item.platform"
                      placeholder="创新创业平台"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="认定部门：">
                    <el-input
                      rows="5"
                      v-model.trim="item.recognitionDepartment"
                      placeholder="认定部门"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="获评时间：">
                    <el-date-picker
                      :picker-options="pickerOptions"
                      v-model="item.evaluationTime"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="级别：">
                    <el-select
                      clearable
                      v-model.trim="item.level"
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="items in level"
                        :key="items.value"
                        :value="items.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-row>
              <el-col>
                <el-form-item
                  label="单位地址"
                  prop="details"
                  ref="ruleAddress"
                  class="addresses"
                >
                  <el-cascader
                    :options="china"
                    @change="handleAddressResses"
                    v-model="addresses"
                    :placeholder="form.address"
                    style="width: 300px; margin: 0 0 5px 0"
                  >
                  </el-cascader>
                  <v-text-field
                    v-model="form.details"
                    placeholder="请输入单位地址"
                    outlined
                    dense
                    @input="resetForm()"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-form-item label="单位简介：">
                  <el-input
                    type="textarea"
                    rows="5"
                    v-model="form.introductionContent"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7" :offset="1" :class="isMobile ? 'Mobilecol' : ''">
            <el-row>
              <el-col>
                <el-card shadow="never">
                  <div
                    style="height: 1px;width: 88%;background-color: #e4e4e4;position: absolute;top: 48px;"
                  ></div>
                  <el-form-item
                    :class="isMobile ? '' : 'Updateitme'"
                    class="uploadfic"
                  >
                    <el-upload
                      :disabled="isUpdateFiles"
                      class="upload-demo"
                      :action="host + '/file'"
                      :on-success="businessLicense"
                      :before-upload="beforeAvatarUpload"
                      :headers="importHeaders"
                      accept=".png,.jpg,.jpeg"
                      :on-remove="handleRemoveBusiness"
                      :before-remove="beforeRemove"
                      :file-list="business"
                      show-file-list
                    >
                      <div style="margin-left: 40px;text-align: left">
                        <el-button
                          size="small"
                          round
                          :disabled="isUpdateFiles"
                          @click="setlabel('工商营业执照')"
                          style="color: #0c94ea;"
                          >点击上传
                        </el-button>
                      </div>

                      <div style="float: left; text-align: left">
                        <div>
                          <span style="color: red">* </span>工商营业执照：
                        </div>
                        <div
                          style="float: left;margin-top: -12px;font-size: 12px"
                        >
                          <span style="color:red">5mb内</span>
                        </div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-card>
              </el-col>
            </el-row>

            <div class="hr-10"></div>

            <el-row>
              <el-col>
                <el-card shadow="never">
                  <div
                    style="height: 1px;width: 88%;background-color: #e4e4e4;position: absolute;top: 48px;"
                  ></div>
                  <el-form-item
                    :class="isMobile ? '' : 'Updateitme'"
                    class="uploadfic"
                  >
                    <el-upload
                      :disabled="isUpdateFiles"
                      class="upload-demo"
                      :action="host + '/file'"
                      :before-upload="beforeAvatarUpload"
                      :headers="importHeaders"
                      :on-success="handleChange"
                      accept=".png,.jpg,.jpeg"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :file-list="lwpqfile"
                    >
                      <div style="margin-left: 40px;text-align: left">
                        <el-button
                          size="small"
                          round
                          :disabled="isUpdateFiles"
                          @click="setlabel('是否有劳务派遣')"
                          style="color: #0c94ea;"
                          >点击上传
                        </el-button>
                      </div>
                      <div style="float: left; text-align: left">
                        <div>是否有劳务派遣：</div>
                        <div
                          style="float: left;margin-top: -12px;font-size: 12px"
                        >
                          <span style="color:red">5mb内</span>
                        </div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-row style="margin: 24px 0 64px 0">
      <el-col :span="8" :offset="4">
        <el-button
          type="info"
          @click="onSubmit('form')"
          :disabled="xxzhuant !== 0"
          >存为草稿
        </el-button>
        <div style="font-size: 12px">
          新注册用户填写请先存为草稿，再提交审核！
        </div>
      </el-col>
      <el-col :span="8">
        <el-button
          type="primary"
          class="text"
          @click="onSubmit('form', 2)"
          :disabled="xxzhuant === 0"
          >提交审核
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import configDate from "@/utils/config";
import china from "@/utils/china_regions";

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
      labelPosition: "left",
      isMobile: false,
      dwtj: false,
      loading: false,
      callIpone: false,
      isUpdateFiles: false,
      ruleAddress: {},
      rulesMessages: "",
      back: "",
      importHeaders: { token: sessionStorage.getItem("Token") },
      filelabel: "",
      china: china,
      zans: "",
      xxzhuant: 0,
      host: configDate.url,
      lwpqfile: [], //是否有劳务派遣
      yyzzfile: [], //工商营业执照
      business: [], // 工商营业执照
      businessUpload: true,
      fczmfile: [], //房产证明或场地租
      otherfile: [], //其他附件
      addresses: [], // 详细地址
      level: [
        { name: "国家级", value: 11 },
        { name: "省级", value: 12 },
        { name: "市级", value: 13 },
        { name: "区级", value: 14 },
        { name: "其他", value: 15 },
      ],
      form: {
        addresses: [], //地址
        address: "",
        addressId: 0,
        details: "",
        cellphone: "", //联系方式
        academicianWorkstation: "", //所属院士工作站
        postDoctoralResearchCenter: "", //所属博士后工作站
        contacts: "", //企业联系人
        corp: "", //企业法人
        email: "", //邮箱
        establishTime: "", //成立时间
        bankCard: "", // 单位银行账号
        bankDeposit: "", //  单位银行开户行
        payee: "", //  单位银行收款人
        fileIds: [], //文件id
        highTech: false, //是否高新企业
        adsEnterprise: false, //是否规上企业
        //id
        id: sessionStorage.getItem("ID"),
        industry: "", //所属行业
        introductionContent: "", //企业介绍
        irdRegistrationCode: "", //务局登记号
        licenseCode: "", //企业营业执证件号
        name: "", //企业名称
        nature: "", //企业性质
        organizationCode: "", //组织机构代码
        registeredCapital: 0, //注册资本
        returneesEntrepreneurial: false, //是否是留创项目
        socialCreditCode: "", //统一社会信用代码
        staffCount: 0, //员工人数
        status: 0, //状态
        subarea: "", //所在分区
        telephone: "", //固定电话
        type: "", //企业类型
        website: "", //企业网站
        innovationPlatforms: [],
      },
      innovationPlatforms: {
        platform: "", // 创新创业平台
        recognitionDepartment: "", // 认定部门
        evaluationTime: "", // 获评时间
        level: "", // 级别
      },
      fileList: [],
      rules: {
        cellphone: [
          //联系方式
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^1[3|456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        platform: [
          { min: 35, message: "请输入三十五个以上的汉字", trigger: "blur" },
        ],
        payee: [
          { required: true, message: "请输入单位银行收款人", trigger: "blur" },
        ],
        bankDeposit: [
          { required: true, message: "请输入单位银行开户行", trigger: "blur" },
        ],
        bankCard: [
          { required: true, message: "请输入单位银行账户", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入单位联系人", trigger: "blur" },
        ], //企业联系人
        corp: [{ required: true, message: "请输入单位法人", trigger: "blur" }], //企业法人
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }], //邮箱
        industry: [
          { required: true, message: "请输入所属行业", trigger: "change" },
        ], //所属行业
        introductionContent: [
          { required: true, message: "请输入单位介绍", trigger: "blur" },
        ], //企业介绍
        irdRegistrationCode: [
          { required: true, message: "请输入务局登记号", trigger: "blur" },
        ], //务局登记号
        licenseCode: [
          {
            required: true,
            message: "请输入单位营业执证件号",
            trigger: "blur",
          },
        ], //企业营业执证件号
        name: [{ required: true, message: "请输入单位名称", trigger: "blur" }], //企业名称
        nature: [
          { required: true, message: "请输入单位性质", trigger: "change" },
        ], //企业性质
        organizationCode: [
          { required: true, message: "请输入组织机构代码", trigger: "blur" },
        ], //组织机构代码
        registeredCapital: [
          { required: true, message: "请输入注册资本", trigger: "blur" },
        ], //注册资本
        socialCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ], //统一社会信用代码
        staffCount: [
          { required: true, message: "请输入员工人数", trigger: "blur" },
        ], //员工人数
        subarea: [
          { required: true, message: "请选择所在分区", trigger: "change" },
        ], //所在分区
        academicianWorkstation: [
          {
            required: true,
            message: "请选择所属院士工作站",
            trigger: "change",
          },
        ], //

        telephone: [
          { required: true, message: "请输入固定电话", trigger: "blur" },
        ], //固定电话
        type: [{ required: true, message: "请输入单位类型", trigger: "blur" }], //企业类型
        addresses: [
          { required: true, message: "请输入地址", trigger: "change" },
        ],
        details: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        establishTime: [
          { required: true, message: "请选择注册日期", trigger: "change" },
        ], //注册日期
      },
    };
  },
  created() {
    this.getDate();
  },
  mounted() {
    if (this.commonFunction._isMobile()) {
      this.isMobile = true;
      this.labelPosition = "top";
    } else {
      this.isMobile = false;
      this.labelPosition = "left";
    }
  },
  methods: {
    //设置备用类型
    setlabel(lebalname) {
      this.filelabel = lebalname;
    },
    //禁止选择未来时间
    dealDisabledDate(time) {
      let times = Date.now();
      return time.getTime() > times;
    },
    //跳转首页
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    //初始化/回显
    getDate() {
      const self = this;
      this.$axios
        .get("/enterprise/detail?id=" + sessionStorage.getItem("ID"))
        .then(function(res) {
          console.log(res.data.data);
          self.form = res.data.data;
          self.xxzhuant = res.data.data.status;
          self.form.name = res.data.data.name; //企业名称
          self.form.socialCreditCode = res.data.data.socialCreditCode; //统一社会信用代码
          self.form.fileIds = res.data.data.files;
          self.form.contacts = res.data.data.contacts; //企业联系人
          self.form.corp = res.data.data.corp; //企业法人
          self.form.bankCard = res.data.data.bankCard; // 单位银行账户
          self.form.bankDeposit = res.data.data.bankDeposit; //  单位银行开户行
          self.form.payee = res.data.data.payee; //单位银行收款人
          self.form.email = res.data.data.email; //邮箱
          self.form.establishTime = res.data.data.establishTime; //成立时间
          self.form.fileIds = []; //文件id
          self.form.highTech = res.data.data.highTech; //是否高新企业
          self.form.adsEnterprise = res.data.data.adsEnterprise; //是否规上企业
          self.form.id = res.data.data.id;
          self.form.industry = res.data.data.industry; //所属行业
          self.form.irdRegistrationCode = res.data.data.irdRegistrationCode; //务局登记号
          self.form.licenseCode = res.data.data.licenseCode; //企业营业执证件号
          self.form.name = res.data.data.name; //企业名称
          self.form.nature = res.data.data.nature; //企业性质
          self.form.organizationCode = res.data.data.organizationCode; //组织机构代码
          self.form.registeredCapital = res.data.data.registeredCapital; //注册资本
          self.form.returneesEntrepreneurial =
            res.data.data.returneesEntrepreneurial; //是否是留创项目
          self.form.socialCreditCode = res.data.data.socialCreditCode; //统一社会信用代码
          self.form.staffCount = res.data.data.staffCount; //员工人数
          self.form.status = res.data.data.status; //状态
          self.form.subarea = res.data.data.subarea; //所在分区
          self.form.telephone = res.data.data.telephone; //固定电话
          self.form.type = res.data.data.type; //企业类型
          self.form.website = res.data.data.website; //企业网站
          if (res.data.data.account) {
            self.form.cellphone = res.data.data.account.contact; //联系方式
          }
          let response = res.data.data;
          let information = self.form;
          information.address = response.address;
          response.addresses.forEach((item) => {
            information.address =
              "中国" +
              " / " +
              item.province +
              " / " +
              item.city +
              " / " +
              item.county;
            information.addressId = item.id;
            information.details = item.details;
          });
          self.lwpqfile = [];
          self.business = [];
          if (res.data.data.status >= 1) {
            res.data.data.files.forEach(function(arr) {
              self.form.fileIds.push(arr.id);
              switch (arr.memo) {
                case "是否有劳务派遣":
                  self.lwpqfile.push({
                    id: arr.id,
                    name: arr.name,
                    url: configDate.fileurl + arr.path,
                  });
                  break;
                case "工商营业执照":
                  self.business.push({
                    id: arr.id,
                    name: arr.name,
                    url: configDate.fileurl + arr.path,
                  });
                  break;
                case "房产证明或场地租赁合同":
                  self.fczmfile.push({
                    id: arr.id,
                    name: arr.name,
                    url: arr.path,
                  });
                  break;

                case "其他附件":
                  self.otherfile.push({
                    id: arr.id,
                    name: arr.name,
                    url: arr.path,
                  });
                  break;
              }
            });
            self.addresses = res.data.data.addresses;
            if (self.addresses.length)
              self.form.details = self.addresses[0].details;
            self.form.cellphone = res.data.data.cellphone; //联系方式
            self.form.academicianWorkstation =
              res.data.data.academicianWorkstation; //所属院士工作站
            self.form.postDoctoralResearchCenter =
              res.data.data.postDoctoralResearchCenter; //所属博士后工作站
            self.form.innovationPlatforms.forEach((item, index) => {
              if (index === 0) {
                self.innovationPlatforms = item;
              }
              if (index === 0) {
                self.form.innovationPlatforms.splice(index, 1);
              }
            });
            if (self.business[0].name !== undefined) {
              self.businessUpload = false;
            }
            self.form.introductionContent =
              res.data.data.introductionContent.content; //企业介绍
          }
        });
    },

    // 去除地址校验
    resetForm() {
      console.log(this.form.addresses);
      if (this.form.details !== "") {
        this.clearValidate("ruleAddress");
      }
    },
    clearValidate(formName) {
      this.$refs[formName].clearValidate();
    },

    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      //     this.$message.error('图片只能是 png 格式!');
      //     return false
      // }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }
    },
    //修改文件备注
    /**
     * 上传文件回调
     * @param response object
     * @param file Array
     * @param fileList Array
     */
    handleChange(response, file, fileList) {
      this.isUpdateFiles = true;
      let self = this;
      if (response.status === 200) {
        let data = {
          id: response.data.id,
          memo: this.filelabel,
        };
        this.$axios.put("/file/record", data).then(function(res) {
          if (res.data.status === 200) {
            self.form.fileIds.push(response.data.id);
            if (res.data.data !== "更新失败") {
              self.$message({
                showClose: true,
                message: "上传成功",
                type: "success",
              });
              self.isUpdateFiles = false;
            } else {
              self.$message({
                showClose: true,
                message: "上传失败",
                type: "warning",
              });
              self.isUpdateFiles = false;
            }
          }
        });
      } else {
        self.$message({
          showClose: true,
          message: "上传失败",
          type: "warning",
        });
      }
    },

    /**
     * 工商执照
     */
    businessLicense(response) {
      this.isUpdateFiles = true;
      let self = this;
      if (response.status === 200) {
        let data = {
          id: response.data.id,
          memo: self.filelabel,
        };
        this.$axios.put("/file/record", data).then(function(res) {
          if (res.data.status === 200) {
            self.form.fileIds.push(response.data.id);
            self.businessUpload = false;
            if (res.data.data !== "更新失败") {
              self.$message({
                showClose: true,
                message: "上传成功",
                type: "success",
              });
              self.isUpdateFiles = false;
            } else {
              self.$message({
                showClose: true,
                message: "上传失败",
                type: "warning",
              });
              self.isUpdateFiles = false;
            }
          }
        });
      } else {
        self.$notify({
          title: "上传失败",
          message: response.data,
          type: "warning",
        });
      }
    },

    //设置地址
    /**
     *
     * @param value Array
     */
    handleResses(value) {
      this.form.addresses[0].city = value[1];
      this.form.addresses[0].province = value[2];
      this.form.addresses[0].county = value[3];
    },

    // 添加创新创业平台
    getAddInnovationPlatforms() {
      this.form.innovationPlatforms.push({
        number:
          "innovationPlatforms" + this.form.innovationPlatforms.length + 1,
        platform: "", // 创新创业平台
        recognitionDepartment: "", // 认定部门
        evaluationTime: "", // 获评时间
        level: "", // 级别
        isAdd: true,
      });
    },

    // 删除创新创业平台
    deleteInnovationPlatforms(item, index) {
      if (item.isAdd) {
        this.form.innovationPlatforms.splice(index, 1);
      } else {
        this.$axios.delete("/platform?idList=" + item.id).then(function() {
          this.getDate();
        });
      }
    },

    goHome() {
      this.$router.go(-1);
    },
    //验证提交
    /**
     *
     * @param form
     * @param formName object
     */
    onSubmit(form, formName) {
      if (this.dwtj) return;
      this.dwtj = true;
      setTimeout(() => {
        this.dwtj = false;
      }, 2000);
      let self = this;
      // if (self.businessUpload) {
      //     self.$root.warn("请上传文件")
      //     return false
      // }
      self.form.innovationPlatforms = [];
      self.$refs[form].validate((valid) => {
        if (valid) {
          self.$nextTick(() => {
            self.$refs[form].validate((valid) => {
              if (valid) {
                let address = [];
                address.push({
                  province: self.addresses[1],
                  city: self.addresses[2],
                  county: self.addresses[3],
                  details: self.form.details,
                  id: self.form.addressId,
                });
                if (self.addresses[0].province === undefined) {
                  self.form.addresses = address;
                } else {
                  self.form.addresses = self.addresses;
                  self.form.addresses[0].details = self.form.details;
                }
                self.form.innovationPlatforms.unshift(self.innovationPlatforms);
                if (formName !== 2) {
                  // 存草稿
                  //保存
                  this.form.status = 1;
                  self.$axios
                    .post("/enterprise/authentication", this.form)
                    .then(function(res) {
                      if (res.data.status === 200) {
                        self.$root.success("保存成功");
                        //重新加载 回显
                        self.getDate();
                      } else {
                        self.$root.warn("保存失败");
                        self.form.innovationPlatforms.shift();
                      }
                    });
                } else {
                  self.form.status = formName;
                  //提交
                  if (self.xxzhuant === 2) {
                    this.$confirm(
                      "您的身份已经保存，提交后需要2~3个工作日审核，确定要提交吗？",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {
                        //更新认证
                        self.putRenz();
                      })
                      .catch(() => {
                        this.$message({
                          type: "info",
                          message: "已取消更新",
                        });
                        // self.getDate()
                        self.form.innovationPlatforms.shift();
                      });
                  } else if (self.xxzhuant === 21) {
                    this.$confirm(
                      "您的身份已认证，提交后需要2~3个工作日重新审核，确定要重新提交吗？",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {
                        //更新认证
                        self.putRenz();
                      })
                      .catch(() => {
                        this.$message({
                          type: "info",
                          message: "已取消更新",
                        });
                      });
                  } else {
                    self.putRenz();
                  }
                }
              } else {
                self.$root.warn("保存失败,请检查表单是否填写完整文件是否上传");
                return false;
              }
            });
          });
        } else {
          self.$root.warn("保存失败,请检查表单是否填写完整文件是否上传");
        }
      });
    },
    //更新认证
    putRenz() {
      let self = this;
      self.$axios.put("/enterprise", self.form).then(function(res) {
        if (res.data.status === 200) {
          self.$notify({
            title: "成功",
            message: "更新成功",
            type: "success",
          });
          self.$router.push({ path: "/main/enterprise" });
        } else {
          self.$root.warn("提交失败");
          self.form.innovationPlatforms.shift();
        }
      });
    },

    // 单位地址
    handleAddressResses(value) {
      this.addresses = value;
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    //文件删除
    handleRemove(file) {
      let id = file.id || file.response.data.id;
      let self = this;
      let newfileId = [];
      this.$axios.delete("/file/delete?id=" + id).then(function(res) {
        if (res.data.status === 200) {
          self.form.fileIds.forEach(function(arr) {
            if (arr !== id) {
              newfileId.push(arr);
            }
          });
          self.form.fileIds = newfileId;
        }
      });
    },
    //工商营业执照文件删除
    handleRemoveBusiness(file) {
      let id = file.id || file.response.data.id;
      let fileId = "";
      let self = this;
      let newfileId = [];
      this.$axios.delete("/file/delete?id=" + id).then(function(res) {
        if (res.data.status === 200) {
          self.business.forEach((item, index) => {
            if (item.id === id) {
              self.business.splice(index, 1);
            }
          });
          console.log(self.business);
          self.form.fileIds.forEach(function(arr) {
            if (arr !== id) {
              newfileId.push(arr);
            }
          });
          self.form.fileIds = newfileId;
          if (self.business.length === 0) {
            self.businessUpload = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  text-align: left;
}

.el-form-item {
  text-align: left;
}

.red {
  font-size: 12px;
  color: #ef5459;
}

.zans {
  border: 1px #ddd solid;
  height: 35px;
  display: block;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 0 0 13px;
  margin: 0 0 5px 0;
  width: 300px;

  button {
    float: right;
    margin: 4px 5px 0 0;
  }
}

.text {
  color: #ffffff;
}
.Updateitme {
  margin: 0 0 0 -130px;
}
@import "../../../styles/common_qyrc.less";
</style>
