import { render, staticRenderFns } from "./qyprocessing.vue?vue&type=template&id=65975680&scoped=true&"
import script from "./qyprocessing.vue?vue&type=script&lang=js&"
export * from "./qyprocessing.vue?vue&type=script&lang=js&"
import style0 from "./qyprocessing.vue?vue&type=style&index=0&id=65975680&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65975680",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VRow,VTextField})
